import { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

interface props {
  mediaDesktop: {
    url: string
  }
  mediaMobile?: {
    url: string
  }
  // Sets which screen size to show mobile/desktop media
  breakPoint?: number
  settings?: {
    [key: string]: boolean | string
  }
  style?: string
}

const DynanmicVideoInView: FC<props> = ({ mediaDesktop, mediaMobile, breakPoint, settings, style = '' }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [mediaSource, setMediaSource] = useState({ url: '' })
  const [inView, setInView] = useState(false)
  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    const resizeListener = () => {
      if (breakPoint && window.innerWidth < breakPoint && mediaMobile?.url) {
        if (mediaSource !== mediaMobile) {
          setMediaSource(mediaMobile)
        }
      } else {
        if (mediaSource != mediaDesktop) {
          setMediaSource(mediaDesktop)
        }
      }
    }
    window.addEventListener('resize', resizeListener)

    if (!videoRef.current?.src) {
      setMediaSource(breakPoint && window?.innerWidth < breakPoint && mediaMobile?.url ? mediaMobile : mediaDesktop)
    }

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [videoRef, mediaDesktop, mediaMobile, breakPoint, mediaSource])

  useEffect(() => {
    videoRef.current?.load()
  }, [mediaSource])

  useEffect(() => {
    if (inView && videoRef.current?.paused) {
      videoRef.current?.play()
    } else {
      if (!videoRef.current?.paused) {
        videoRef.current?.pause()
      }
    }
  }, [mediaSource, inView])

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.IntersectionObserver !== 'undefined') {
      observer.current = new window.IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            const video = videoRef.current as HTMLVideoElement
            if (entry.isIntersecting) {
              setInView(true)
            } else {
              setInView(false)
            }
          })
        },
        { rootMargin: '-20px 0px -20px 0px' }
      )

      if (videoRef.current) {
        observer.current?.observe(videoRef.current)
      }
    }

    return () => {
      observer.current?.disconnect()
    }
  }, [videoRef, observer])

  return (
    <video ref={videoRef} className={cn(`dynamic-video w-full h-full object-cover ${style}`)} {...settings}>
      <source src={mediaSource.url} type="video/mp4" />
    </video>
  )
}

export default DynanmicVideoInView
