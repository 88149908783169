import commerceConfig from '@commerce/config'
import DynanmicVideoInView from '@components/common/DynamicVideo/DynamicVideoInView'
import { WYSIWYG } from '@components/ui'
import { PrismicImage } from '@lib/prismic'
import cn from 'classnames'
import 'keen-slider/keen-slider.min.css'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { RichTextBlock } from 'prismic-reactjs'
import { FC } from 'react'
import tailwindConfig from 'tailwind.config.js'
import resolveConfig from 'tailwindcss/resolveConfig'
const fullConfig = resolveConfig(tailwindConfig as any)

interface PLPFeaturesProps {
  index?: number
  collectionImage: PrismicImage | null
  newTag: [] | any
  limitedEditionTag: [] | any
  saleTag: [] | any
  salePercentage: string
  collectionIcon: PrismicImage | null
  collectionTitle: string
  collectionDescription: RichTextBlock[]
  collectionLink: string
  mobileSize?: boolean
  panel_text_style?: 'dark' | 'light'
  video?: {
    url: string | any
  }
}

const CollectionCard: FC<PLPFeaturesProps> = ({
  index,
  collectionImage,
  newTag,
  limitedEditionTag,
  saleTag,
  salePercentage,
  collectionIcon,
  collectionTitle,
  collectionDescription,
  collectionLink,
  mobileSize,
  panel_text_style,
  video,
}) => {
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { screens } = fullConfig.theme as Theme
  const breakPointNum = parseInt(screens['tablet'].split('px').join(''))
  return (
    <Link
      href={collectionLink || '/'}
      passHref
      className={cn(' tablet:w-full cursor-pointer', {
        // 'w-5/6 h-5/6': mobileSize,
      })}
      key={index}
      legacyBehavior
    >
      <div>
        <div
          className={cn('w-full relative cursor-pointer', {
            'mb-12': collectionTitle && collectionDescription,
          })}
        >
          {collectionImage?.url && (
            <Image
              src={collectionImage?.url}
              alt={collectionImage?.alt || defaultAlt}
              width={395}
              height={395}
              layout="responsive"
              sizes="(min-width: 1024px) 332px, 100vw"
            />
          )}
          {video?.url && (
            <DynanmicVideoInView
              mediaDesktop={video}
              mediaMobile={video}
              breakPoint={breakPointNum}
              settings={{ playsInline: true, loop: true, muted: true }}
              style="absolute inset-0"
            />
          )}
          <div className="absolute top-0 flex">
            {newTag?.length > 0 && (
              <div className=" bg-newProduct py-6 px-16 title-small text-white mr-6 uppercase">New</div>
            )}
            {limitedEditionTag?.length > 0 && (
              <div className=" bg-limitedEdition  py-6 px-16 title-small text-white mr-6 uppercase">
                Limited Edition
              </div>
            )}
            {saleTag?.length > 0 && (
              <div className=" bg-sale  py-6 px-16 title-small text-white uppercase">
                {salePercentage && salePercentage[1]} OFF
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="flex pb-4">
            <div className="flex items-center">
              {collectionIcon?.url && (
                <div className="w-12 h-12">
                  <Image
                    src={collectionIcon?.url}
                    alt={collectionImage?.alt || defaultAlt}
                    width={collectionIcon?.dimensions?.width || 12}
                    height={collectionIcon?.dimensions?.height || 12}
                  />
                </div>
              )}

              {collectionTitle && (
                <h3
                  className={cn(`body-bold ${panel_text_style === 'light' ? 'text-white' : ''}`, {
                    'ml-6': collectionIcon?.url,
                  })}
                >
                  {collectionTitle}
                </h3>
              )}
            </div>
          </div>
          {collectionDescription && (
            <div className={`body ${panel_text_style === 'light' ? 'text-white' : ''} `}>
              {' '}
              {<WYSIWYG content={collectionDescription} />}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default CollectionCard
